import React, { FC, useContext } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { LoginPage } from '../../app/auth/LoginPage';
import { Register } from '../../app/auth/RegisterPage';
import { HomePage } from '../../app/home/HomePage';
import { AccountPage } from '../../app/account/AccountPage';
import MainLayout from '../Layout/MainLayout';
import AuthLayout from '../Layout/AuthLayout';
import { AuthContext } from '../auth/authContext';
import AccountDetails from '../../app/account/components/accountDetails';
import AccountDetailsEdit from '../../app/account/components/accountDetailsEdit';

const AppRoutes: FC = () => {
  const currentUser = useContext(AuthContext);

  return (
    <BrowserRouter>
      <Routes>
        
        {currentUser ? (
          <>
            <Route path='/' element={<MainLayout><HomePage /></MainLayout>} />
            {/* Account Routes */}
            <Route path="/account" element={<MainLayout><AccountPage /></MainLayout>}>
              <Route path="overview" element={<AccountDetails />} />
              <Route path="settings" element={<AccountDetailsEdit />} />
            </Route>
            <Route path='*' element={<Navigate to='/' />} />
          </>
        ) : (
          <>
            <Route path='/login' element={<AuthLayout><LoginPage /></AuthLayout>} />
            <Route path='/register' element={<AuthLayout><Register /></AuthLayout>} />
            <Route path='*' element={<Navigate to='/login' />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
