import React, { ReactNode } from 'react';

interface AuthLayoutProps {
  children: ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <div className="d-flex flex-column flex-root" id="kt_app_root">
        {/* begin::Authentication - Sign-up */}
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">

            {children}

        </div>
    </div>
  );
};

export default AuthLayout;

// Add this line to ensure the file is treated as a module
export {};
