import React from "react";
import { Link } from "react-router-dom";

const Notice: React.FC = () => {
    return (
        <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
          {/* Icon */}
          <i className="ki-duotone ki-information fs-2tx text-warning me-4">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>
          {/* End Icon */}
          {/* Wrapper */}
          <div className="d-flex flex-stack flex-grow-1">
            {/* Content */}
            <div className="fw-semibold">
              <h4 className="text-gray-900 fw-bold">Subscribe to get all the features!</h4>
              <div className="fs-6 text-gray-700">
                To start getting all the benefits, please{" "}
                <Link to="/account/billing" className="fw-bold">
                  Subscribe.
                </Link>.
              </div>
            </div>
            {/* End Content */}
          </div>
          {/* End Wrapper */}
        </div>
    );
};

export default Notice;