import React, { createContext, useContext, useState, useEffect } from 'react';
import { UserIdentityPayload } from '../../api/userIdentityApi';

interface UserContextType {
    userIdentity: UserIdentityPayload | null;
    setUserIdentity: React.Dispatch<React.SetStateAction<UserIdentityPayload | null>>;
    signOut: () => void; // Add signOut to the context type
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [userIdentity, setUserIdentity] = useState<UserIdentityPayload | null>(() => {
        // Retrieve initial state from localStorage if available
        const savedIdentity = localStorage.getItem('userIdentity');
        return savedIdentity ? JSON.parse(savedIdentity) : null;
    });

    useEffect(() => {
        // Store userIdentity in localStorage whenever it changes
        if (userIdentity) {
            localStorage.setItem('userIdentity', JSON.stringify(userIdentity));
        } else {
            localStorage.removeItem('userIdentity');
        }
    }, [userIdentity]);

    // Signout function to clear user identity and remove from localStorage
    const signOut = () => {
        setUserIdentity(null);
        localStorage.removeItem('userIdentity');
    };

    return (
        <UserContext.Provider value={{ userIdentity, setUserIdentity, signOut }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = (): UserContextType => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};
