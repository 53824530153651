import React from "react";
import { Link, useMatch, useLocation } from "react-router-dom";

export const ToolBar: React.FC = () => {
  const location = useLocation();
  const matchAccount = useMatch("/account/*");
  const matchDashboard = useMatch("/dashboards");

  const getPageDetails = () => {
    if (matchAccount) {
      return {
        title: "Account",
        breadcrumbs: ["Home", "Account"],
        actions: [],
      };
    }

    if (matchDashboard) {
      return {
        title: "Dashboards",
        breadcrumbs: ["Home", "Dashboards"],
        actions: [
          {
            label: "Add Customer",
            to: "/apps/customers/list",
            className: "btn-secondary",
          },
          {
            label: "New Shipment",
            to: "/apps/ecommerce/sales/add-order",
            className: "btn-primary",
          },
        ],
      };
    }

    switch (location.pathname) {
      case "/":
        return {
          title: "Home",
          breadcrumbs: ["Home"],
          actions: [],
        };
      default:
        return {
          title: "Page Not Found",
          breadcrumbs: ["Home", "404"],
          actions: [],
        };
    }
  };

  const { title, breadcrumbs, actions } = getPageDetails();

  return (
    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
      {/* Toolbar container */}
      <div
        id="kt_app_toolbar_container"
        className="app-container container-xxl d-flex flex-stack"
      >
        {/* Page title */}
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          {/* Title */}
          <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
            {title}
          </h1>
          {/* Breadcrumb */}
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            {breadcrumbs.map((crumb, index) => (
              <li
                key={index}
                className={`breadcrumb-item ${
                  index === breadcrumbs.length - 1 ? "text-muted" : ""
                }`}
              >
                {index === breadcrumbs.length - 1 ? (
                  crumb
                ) : (
                  <Link to="/" className="text-muted text-hover-primary">
                    {crumb}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
        {/* Actions */}
        <div className="d-flex align-items-center gap-2 gap-lg-3">
          {actions.map((action, index) => (
            <Link
              key={index}
              to={action.to}
              className={`btn btn-sm fw-bold ${action.className}`}
            >
              {action.label}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ToolBar;
