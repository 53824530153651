import React, { useState, useEffect } from "react";
import { AccountProfile, getAccountProfileByID} from "../../../api/accountProfileApi";
import { postAccountProfile } from "../../../api/accountProfileApi";
import { useUser } from "../../../Components/context/UserContext";
import { useNavigate } from "react-router-dom";

const AccountDetailsEdit: React.FC = () => {
  const { userIdentity } = useUser();
  const navigate = useNavigate(); // Set up navigate for routing

  // Initial state for form fields
  const [formData, setFormData] = useState<AccountProfile>({
    userId: userIdentity?.userId || '',
    email: userIdentity?.email || '',
    companyname: '',
    phonenbr: '',
    website: '',
});

const [error, setError] = useState<string | null>(null);
const [success, setSuccess] = useState<boolean>(false);


useEffect(() => {
  
  // Only fetch data if userIdentity.userId is available
  if (userIdentity?.userId) {
    const fetchProfile = async () => {
      try {
        const profileData = await getAccountProfileByID(userIdentity.userId);
        setFormData(profileData); // Set formData with fetched profile data
      } catch (err) {
        setError("There was an error. Please try again.");
      }
    };
    fetchProfile();
  }
}, [userIdentity?.userId]);

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

// Handle form submission
const handleSubmit = async (e: React.FormEvent) => {
  e.preventDefault();
  setError(null); // Reset error before submission
  setSuccess(false); // Reset success message before submission

  try {
    const response = await postAccountProfile(formData);
    if (response.status === 200) {
      setSuccess(true); // Indicate successful submission
      console.log("Profile created successfully:", response.data);
    }
  } catch (error) {
    setError(error instanceof Error ? error.message : "An unexpected error occurred");
  }
};

 // Handle Discard button click
 const handleDiscard = () => {
  navigate('/account/overview'); // Route to /overview page
};

return (
  <div className="card mb-5 mb-xl-10">
    <div className="card-header border-0 cursor-pointer">
      <div className="card-title m-0">
        <h3 className="fw-bold m-0">Profile Details</h3>
      </div>
    </div>

    <div id="kt_account_settings_profile_details" className="collapse show">
      <form id="kt_account_profile_details_form" className="form" onSubmit={handleSubmit}>
        <div className="card-body border-top p-9">
        {error && <div className="alert alert-danger">{error}</div>}
        {success && <div className="alert alert-success">Update saved successfully!</div>}

          {/* Company Name */}
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Company</label>
            <div className="col-lg-8 fv-row">
              <input
                type="text"
                name="companyname"
                className="form-control form-control-lg form-control-solid"
                placeholder="Company name"
                value={formData.companyname}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Contact Phone */}
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Contact Phone</label>
            <div className="col-lg-8 fv-row">
              <input
                type="tel"
                name="phonenbr"
                className="form-control form-control-lg form-control-solid"
                placeholder="Phone number"
                value={formData.phonenbr}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Company Website */}
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Company Site</label>
            <div className="col-lg-8 fv-row">
              <input
                type="text"
                name="website"
                className="form-control form-control-lg form-control-solid"
                placeholder="Company website"
                value={formData.website}
                onChange={handleChange}
              />
            </div>
          </div>

        </div>

        {/* Form Actions */}
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="reset" className="btn btn-light btn-active-light-primary me-2" onClick={handleDiscard}>
            Discard
          </button>
          <button type="submit" className="btn btn-primary">
            Save Changes
          </button>
        </div>
      </form>
    </div>
  </div>
);
};

export default AccountDetailsEdit;
