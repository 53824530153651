import React from 'react';
import LoginForm from './components/login-form';
import SignUpFooter from './components/sign-up-footer';
import SignUpAside from './components/sign-up-aside';

const LoginPage: React.FC = () => {
  return (
    <>
      {/* begin::Body */}
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
        <LoginForm />
        <SignUpFooter />
      </div>
      {/* end::Body */}
        <SignUpAside/>
    </>
  );
};

export { LoginPage };
