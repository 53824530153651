import React, { useEffect } from 'react';
import { AccountNavs } from './navs';
import NavbarHeader from './navheader';

export const AccountNavBar = () => {
    return (
     
          <div className="card-body pt-9 pb-0">
            {/* Begin Details */}

            {/* <NavbarHeader/> */}
            
            {/* End Details */}

            {/* Begin Navs */}
            <AccountNavs/>
            {/* End Navs */}
          </div>

);
};

export default AccountNavBar;            