import axios from 'axios';

// types.ts
export interface AccountProfile {
    userId: string;
    email: string;
    companyname: string;
    phonenbr: string;
    website: string;
}

export const postAccountProfile = async (accountprofilePayload: AccountProfile) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/AccountProfile`;
        const response = await axios.post(url, accountprofilePayload);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || 'Failed to create profile');
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const getAccountProfileByID = async (userID: string) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/AccountProfile/${userID}`;
        const response = await axios.get(url);
        return response.data; // Return the response data directly
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || 'Failed to fetch user identity');
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};