import React, { useState } from 'react';
import Header_Wrapper from './header_wrapper';
import { useAppContext } from '../../context/AppContext';
import { Console } from 'console';

export const Header = () => {
    // State to track whether the div is active using the app context
    const { isSidebarActive, toggleSidebarActiveState } = useAppContext();

    return (
        // begin header
        <div id="kt_app_header" className="app-header" data-kt-sticky="true" data-kt-sticky-activate="{default: true, lg: true}" data-kt-sticky-name="app-header-minimize" data-kt-sticky-offset="{default: '200px', lg: '0'}" data-kt-sticky-animation="false">

        {/* begin header container */}
        <div className="app-container container-fluid d-flex align-items-stretch justify-content-between" id="kt_app_header_container">
        {/* <!--begin::Sidebar mobile toggle--> */}
        <div className="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2" title="Show sidebar menu" onClick={toggleSidebarActiveState}>
            <div className={`btn btn-icon btn-active-color-primary w-35px h-35px ${isSidebarActive ? 'active' : ''}`} id="kt_app_sidebar_mobile_toggle">
                <i className="ki-duotone ki-abstract-14 fs-2 fs-md-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                </i>
            </div>
        </div>
        {/* <!--end::Sidebar mobile toggle--> */}

        {/* <!--begin::Mobile logo--> */}
        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
        <a href="index.html" className="d-lg-none">
        <img alt="Logo" src="assets/media/logos/default-small.svg" className="h-30px" />
        </a>
        </div>
        {/* <!--end::Mobile logo--> */}

        <Header_Wrapper/>

        </div>
        {/* end header container */}

        </div>
        // end header
    );
};

export default Header;