import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Notice from "./notice";
import { AccountProfile, getAccountProfileByID } from "../../../api/accountProfileApi";
import { useUser } from "../../../Components/context/UserContext";

const AccountDetails: React.FC = () => {
  const { userIdentity } = useUser();
  const userID = userIdentity?.userId || '';

  const [accountProfile, setAccountProfile] = useState<AccountProfile | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Fetch user profile data when component mounts
    const fetchProfile = async () => {
      try {
        const profileData = await getAccountProfileByID(userID);
        setAccountProfile(profileData);
      } catch (err) {
        setError("Failed to fetch profile details.");
      }
    };

    fetchProfile();
  }, [userID]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!accountProfile) {
    return <div>Loading...</div>;
  }

  return (
    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
      {/* Card header */}
      <div className="card-header cursor-pointer">
        {/* Card title */}
        <div className="card-title m-0">
          <h3 className="fw-bold m-0">Company Details</h3>
        </div>
        {/* End Card title */}
        {/* Action */}
        <Link to="/account/settings" className="btn btn-sm btn-primary align-self-center">
          Edit Profile
        </Link>
        {/* End Action */}
      </div>
      {/* End Card header */}

      {/* Card body */}
      <div className="card-body p-9">
        {/* Row */}

        {/* Input group */}
        <div className="row mb-7">
          <label className="col-lg-4 fw-semibold text-muted">Company Name</label>
          <div className="col-lg-8 fv-row">
            <span className="fw-semibold text-gray-800 fs-6">{accountProfile.companyname}</span>
          </div>
        </div>
        {/* End Input group */}

        {/* Input group */}
        <div className="row mb-7">
          <label className="col-lg-4 fw-semibold text-muted">
            Contact Phone 
            <span className="ms-1" data-bs-toggle="tooltip" title="Phone number must be active">
              <i className="ki-duotone ki-information fs-7">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i>
            </span>
          </label>
          <div className="col-lg-8 d-flex align-items-center">
            <span className="fw-bold fs-6 text-gray-800 me-2">{accountProfile.phonenbr}</span>
            {/* <span className="badge badge-success">Verified</span> */}
          </div>
        </div>
        {/* End Input group */}

        {/* Input group */}
        <div className="row mb-7">
          <label className="col-lg-4 fw-semibold text-muted">Website URL</label>
          <div className="col-lg-8">
            <a href="#" className="fw-semibold fs-6 text-gray-800 text-hover-primary">{accountProfile.website}</a>
          </div>
        </div>
        {/* End Input group */}

        {/* Input group */}
        <div className="row mb-7">
          <label className="col-lg-4 fw-semibold text-muted">
            Country 
            <span className="ms-1" data-bs-toggle="tooltip" title="Country of origination">
              <i className="ki-duotone ki-information fs-7">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i>
            </span>
          </label>
          <div className="col-lg-8">
            <span className="fw-bold fs-6 text-gray-800">United States</span>
          </div>
        </div>
        {/* End Input group */}

        {/* Input group */}
        <div className="row mb-7">
          <label className="col-lg-4 fw-semibold text-muted">Communication</label>
          <div className="col-lg-8">
            <span className="fw-bold fs-6 text-gray-800">Email, Phone</span>
          </div>
        </div>
        {/* End Input group */}

        {/* Input group */}
        <div className="row mb-10">
          <label className="col-lg-4 fw-semibold text-muted">Allow Changes</label>
          <div className="col-lg-8">
            <span className="fw-semibold fs-6 text-gray-800">Yes</span>
          </div>
        </div>
        {/* End Input group */}

        {/* Notice */}
          <Notice/>
        {/* End Notice */}


      </div>
      {/* End Card body */}
    </div>
  );
};

export default AccountDetails;
