import React, { useEffect, useState, createContext, ReactNode } from "react";
import { auth } from "../../firebaseConfig";
import { User, onAuthStateChanged } from "firebase/auth";
import { AuthContext } from "./authContext";

interface AuthProviderProps {
    children: ReactNode;
  }
  
  // Create an AuthProvider component to wrap around the app
  export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        setCurrentUser(user);
        setLoading(false); // Set loading to false once Firebase has confirmed auth state
      });
  
      // Cleanup subscription on unmount
      return () => unsubscribe();
    }, []);

    if (loading) {
      return <div>Loading...</div>; // Optionally add a loading indicator here
  }
  
    return (
      <AuthContext.Provider value={currentUser}>
        {children}
      </AuthContext.Provider>
    );
  };
  