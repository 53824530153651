import React, { createContext, useContext, useState, ReactNode, useMemo } from 'react';

// Define the context type
interface AppContextType {
  isSidebarActive: boolean;
  toggleSidebarActiveState: () => void;
  activateSideBar: () => void;
}

// Create the context with a default value
const AppContext = createContext<AppContextType>({
  isSidebarActive: true, // Set the default value here
  toggleSidebarActiveState: () => {}, // Provide a default no-op function
  activateSideBar: () => {}, // Provide a default no-op function
});

// Provider component
const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isSidebarActive, setIsSidebarActive] = useState(true); // Set default state to true

  const toggleSidebarActiveState = () => {
    setIsSidebarActive((prev) => !prev);
    //console.log('New state:', !isSidebarActive);
  };

  const activateSideBar = () => {
    console.log('AppContext - Activating Sidebar');
    setIsSidebarActive(true);
  };

  const value = useMemo(() => ({
    isSidebarActive,
    toggleSidebarActiveState,
    activateSideBar
  }), [isSidebarActive]);

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use the context
const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

export { AppProvider, useAppContext };
