import React from "react";
import { Link } from "react-router-dom";

export const EngageWidget: React.FC = () => {
  return (
    <div className="card h-md-100" dir="ltr">
      {/* Begin::Body */}
      <div className="card-body d-flex flex-column flex-center">
        {/* Begin::Heading */}
        <div className="mb-2">
          {/* Begin::Title */}
          <h1 className="fw-semibold text-gray-800 text-center lh-lg">
            Setup your
            <br />
            <span className="fw-bolder">Account Profile</span>
          </h1>
          {/* End::Title */}
          {/* Begin::Illustration */}
          <div className="py-10 text-center">
            <img
              src="assets/media/svg/illustrations/easy/3.svg"
              className="theme-light-show w-200px"
              alt="Light theme illustration"
            />
            <img
              src="assets/media/svg/illustrations/easy/3-dark.svg"
              className="theme-dark-show w-200px"
              alt="Dark theme illustration"
            />
          </div>
          {/* End::Illustration */}
        </div>
        {/* End::Heading */}
        {/* Begin::Links */}
        <div className="text-center mb-1">
          {/* Begin::Link */}
          <Link to="/account/overview" className="btn btn-sm btn-primary me-2">
          Start Now
          </Link>
          {/* End::Link */}
          {/* Begin::Link */}
          <a
            className="btn btn-sm btn-light"
            href="apps/invoices/view/invoice-2.html"
          >
            Quick Guide
          </a>
          {/* End::Link */}
        </div>
        {/* End::Links */}
      </div>
      {/* End::Body */}
    </div>
  );
};

export default EngageWidget;
