import React from 'react';
import ReactDOM from 'react-dom';
import { useAppContext } from '../../context/AppContext';
import useWindowWidth from '../utils/windowWidth';

const SidebarOverlay: React.FC = () => {
  const { isSidebarActive, toggleSidebarActiveState } = useAppContext();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 992;

  console.log('SidebarOverlay - isSidebarActive:', isSidebarActive);
  console.log('SidebarOverlay - isMobile:', isMobile);

  if (!isSidebarActive || !isMobile) {
    console.log('SidebarOverlay - Returning Null');
    return null;
  }

  return ReactDOM.createPortal(
    <div
      style={{ zIndex: 105 }}
      className="drawer-overlay"
      onClick={toggleSidebarActiveState}
    ></div>,
    document.getElementById('root-modals') as HTMLElement
  );
};

export default SidebarOverlay;
