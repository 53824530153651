import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC2tjQTNmoj5eg3kV9DkVnI9YliJXwL6xA",
    authDomain: "hoodnestpro.firebaseapp.com",
    projectId: "hoodnestpro",
    storageBucket: "hoodnestpro.appspot.com",
    messagingSenderId: "1096402679251",
    appId: "1:1096402679251:web:a32b0039460ab5bfc4ab26"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export { auth };
