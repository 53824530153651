
export const getErrorMessage = (code: string): string => {
    switch (code) {
        case 'auth/email-already-in-use':
            return 'This email address is already in use.';
        case 'auth/invalid-email':
            return 'The email address is not valid.';
        case 'auth/operation-not-allowed':
            return 'Email/password accounts are not enabled.';
        case 'auth/weak-password':
            return 'The password is too weak.';
        case 'auth/wrong-password':
            return 'The password is incorrect.';
        case 'auth/user-not-found':
            return 'No user found with this email address.';
        default:
            return 'An unexpected error occurred. Please try again.';
    }
};
